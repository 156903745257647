import React from "react";
import { useCreateContext } from "../create-context";
import { reducer, initialState } from "./app.reducer";

// Create a custom React Hook to encapsulate the useContext logic
const useAppContext = () => {
  // Call useCreateContext inside the custom Hook
  const [state, useDispatch, provider] = useCreateContext(
    initialState,
    reducer
  );
  return { state, useDispatch, provider };
};

// Export the custom Hook and the context values
export const useStickyState = () => useAppContext().state;
export const useStickyDispatch = () => useAppContext().useDispatch;
export const StickyProvider = () => useAppContext().provider;
