import { jsx, Image } from "theme-ui";
import { Link } from "./link";
import logo from "../assets/logo.svg";

export default function Logo() {
  return (
    <Link
      path="/"
      sx={{
        variant: "links.logo",
      }}
    >
      <Image src={logo} sx={{ display: "flex" }} alt="startup landing logo" />
    </Link>
  );
}
