export default [
  {
    path: "banner",
    label: "Home",
  },
  {
    path: "services",
    label: "Services",
  },
  {
    path: "testimonials",
    label: "Testimonials",
  },
];
