import React, { useState } from "react";
import { ThemeProvider } from "theme-ui";
import { StickyProvider } from "../contexts/app/app.provider";
import theme from "../theme";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Banner from "./sections/banner";
import Services from "./sections/services";
import Testimonials from "./sections/testimonials";
import CustomerSupport from "./sections/customer-support";
import CallToAction from "./sections/call-to-action";
import BoostAgencies from "./sections/boost-agencies";
import BasicModal from "../components/modal/BasicModal";

export default function IndexPage() {
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <BasicModal open={open} handleClose={handleClose}>
          form goes here
        </BasicModal>

        <Banner />
        <Services />
        <BoostAgencies />

        <CustomerSupport />

        <CallToAction />
      </Layout>
    </ThemeProvider>
  );
}
